@import './variables.css';

/* DASHBOARD */

.dashboardHeader {
    position: relative;
    cursor: default;
    margin-bottom: 2%;
}

.dashboardHeader .title {
    font-size: 24px;
}

.dashboardHeader .title span:first-child { /* "Patient Dashboard" */
    font-weight: 700;
    margin-right: 10px;
}

.dashboardHeader .title span:last-child { /* "Envision Wembley" */
    font-weight: 100 !important;
}

.dashboardHeader .buttons {
    background-color: transparent!important;
    color: var(--text) !important;
    position: absolute; 
    z-index: 5;
    top: 0%;
    right: 1%;
    padding: 0px !important;
    
}

.dashboardHeader .buttons .button{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.prioritiesLegend .selected {
    text-decoration: underline;
}

.prioritiesLegend {
    margin-left: 2%;
}

.prioritiesLegend span {
    margin-left: 1%;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    color: var(--inv_text);
    padding-top: 0.2%;
    padding-bottom: 0.2%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 3px;
    font-weight: bold;
    font-size: 12px;
}

.prioritiesLegend span:hover {
    text-decoration: underline;
}

.prioritiesLegend .lowPriority {
    background-color: var(--lowPriority);
}

.prioritiesLegend .mediumPriority {
    background-color: var(--mediumPriority);
}

.prioritiesLegend .highPriority {
    background-color: var(--highPriority);
}


/* FORM */

.modals {
    background: none; /* override page styling in general.css to make dimmer work */
}

.modal {
    background-color: transparent !important; /* hide white corners */
}

.modal .header, .content, input, label {
    color: var(--text) !important;
    background-color: var(--secondary_bg) !important;
}

.modal .button.submit {
    border: solid;
    border-width: 1px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    color: var(--inv_text) !important;
    background-color: rgb(106, 199, 106) !important;
}

.modal .button.submit:hover {
    background-color: rgb(182, 253, 182) !important;
}

.modal .button {
    padding: 5px;  /* make more  compact */
    border: none !important;
}

.modal .loader {
    padding: 0 !important;
    margin: 0 !important;
}


.dimmer {
    opacity: 1 !important;
}

.button.close {
    border: none;
    position: absolute;
    right: 0;
    top: 0;
}

.requestForm .maxDateWarning.show {
    font-size: 90%;
    color: rgb(233, 138, 138);
}

.requestForm .maxDateWarning.hide {
    color: transparent;
}



/* TABLE VIEW */


.dashboardTable .lowPriority a {
    color: var(--lowPriority) !important;
}

.dashboardTable .highPriority a {
    color: var(--highPriority) !important;
}

.dashboardTable .mediumPriority a {
    color: var(--mediumPriority) !important;
}

.dashboardTable tbody tr:hover {
    font-weight: bold;
    /*background-color: var(--highlight) !important;*/
    cursor: pointer;
}

.dashboardTable .Rejected {
    color: var(--highPriority);
}

.dashboardTable .Approved {
    color: var(--lowPriority);
}

.dashboardTable .headerRow th {
    cursor: pointer;
}

.dashboardTable .headerRow th span:hover {
    text-decoration: underline;
}

/* TILE VIEW */


.dashboardTiles .tile {
    background-color: var(--dashboard_tile_bg);
    border-left: solid;
    border-width: 5px;
    padding: 1%;
    color: var(--text) !important;
    float: left;
    height: auto;
    width: 19.5%;
    margin: 0.25% !important;
    opacity: 1; /* reduce to make dimmer backgrounds */
    -webkit-box-shadow: 0px 2px 4px #000000;
    -moz-box-shadow: 0px 2px 4px #000000;
         box-shadow: 0px 5px 4px 1px rgb(0, 0, 0, 0.3);
}

.dashboardTiles .tile:hover {
    -webkit-box-shadow: 0px 2px 4px #000000;
    -moz-box-shadow: 0px 2px 4px #000000;
         box-shadow: 0px 5px 4px 1px rgb(0, 0, 0, 0.4);
}

.dashboardTiles .tile:hover {
    cursor: pointer;
    color: var(--text) !important;
}

.dashboardTiles .tileRow {
    display: inline-block;
    vertical-align: top; /* to remove gap between rows */
    width: 100%;
}

.dashboardTiles .tile.lowPriority {
    border-color: var(--lowPriority);
}

.dashboardTiles .tile.mediumPriority {
    border-color: var(--mediumPriority);
}

.dashboardTiles .tile.highPriority {
    border-color: var(--highPriority);
}

.dashboardTiles .lowPriority:hover { /* TODO */
    transition-duration: 0.22s;
}

.dashboardTiles .highPriority:hover { /* TODO */
    transition-duration: 0.22s;
}

.dashboardTiles .mediumPriority:hover { /* TODO */
    transition-duration: 0.22s;
}

/* patient name */

.dashboardTiles .tile h3 {
    margin-top: 5%;
    font-size: 18px;
    text-align: left;
    margin-left: 7px; /* align with info below patient name */
}

.dashboardTiles .tile.lowPriority h3 {
    color: var(--lowPriority);
}

.dashboardTiles .tile.mediumPriority h3 {
    color: var(--mediumPriority);
}

.dashboardTiles .tile.highPriority h3 {
    color: var(--highPriority);
}

/* patient info */
.dashboardTiles .tile table {
    background-color: transparent !important;
    text-align: left;
    border: none !important;
    color: var(--text) !important;
    font-size: 14px;

}

.dashboardTiles .tile tr td:first-child { /* change first column style */
    font-size: 12px;
    font-weight: bold;
    width: 60%; /* to ensure Vulnerable Plaque fits on one line */
}

.dashboardTiles .tile td {
    border: none !important;
    padding-top: 2%;
    padding-bottom: 2%;
}

/* the following makes thinner icons */
.dashboardTiles .highPriority .icon {
    -webkit-text-stroke: 2px var(--highPriority) !important;
}

.dashboardTiles .lowPriority .icon {
    -webkit-text-stroke: 2px var(--lowPriority) !important;
}

.dashboardTiles .mediumPriority .icon {
    -webkit-text-stroke: 2px var(--mediumPriority) !important;
}

.dashboardTiles .highPriority:hover .icon {
    -webkit-text-stroke: 2px var(--bg) !important;
}

.dashboardTiles .lowPriority:hover .icon {
    -webkit-text-stroke: 2px var(--bg) !important;
}

.dashboardTiles .mediumPriority:hover .icon {
    -webkit-text-stroke: 2px var(--bg) !important;
}

.dashboardTiles .tile:hover td {
    color: var(--text);
}

