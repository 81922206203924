@import './variables.css';

.centeredBody {
    height: 100%;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.helpButtons {
    margin-top: 5% !important;
}

.helpButtons .button {
    width: 85%;
    background-color: var(--primary_bg) !important;
}

.helpButtons .button.active {
    width: 85%;
    background-color: var(--secondary_bg) !important;
}

.contact, .faq, .suggestionForm {
    margin-top:3%;
}

.contactTitle {
    text-align: center !important;
    margin-top: 5%;
    margin-bottom: 15%;
}

.contactOptions {
    display: table;
    margin: auto;
}

.contactOptions .icon {
    margin-right:10px;
}

.contactOptions .contactEmail {
    cursor: pointer;
}

.contactTitle h3 {
    font-size:150%;
}

.contact div {
    font-size:110%;
}

.contactEmail:hover .icon {
    color:var(--red);
}

.contactTwitter:hover .icon {
    color:#2582d3;
}

.faq {
    color:var(--text)!important;
}

.faq .ans {
    background-color:var(--secondary_bg) !important;
    padding-top:2%;
    padding-left:5%;
    padding-right:5%;
    padding-bottom:2%;
    margin-bottom:3%;
    margin-left:1%;
    margin-right:1%;
    border-radius: 5px;
}

.faq .button {
    background-color: transparent!important;
    color: var(--text) !important;
}

.faq .button:hover {
    color: var(--text);
}

.faq p {
    margin-top:1%;
    margin-left:3%;
}

.faq a {
    text-decoration: underline;
}

.faq a:hover {
    color:var(--text_hl);
}

.faq a span {
    font-weight:550;
    font-size:115%;
}

.faq .table td {
    border:none!important;
}

.faq .table th {
    padding-bottom:5px!important; /*reduces default */
    border-color:var(--text)!important;
}

.faq .table {
    margin-top:3%;
}

.faqQ {
    margin-top:30px;
}

.faqSection {
    font-size:120%;
}

.suggestionForm label {
    color: var(--text) !important;
}

.suggestionForm .text {
    color: var(--text) !important;
}

.suggestionForm .menu {
    background-color: var(--secondary_bg) !important;
}

.suggestionFormInput {
    color:var(--text) !important;
    background-color:var(--secondary_bg) !important;
}

.suggestionFormInput textarea {
    color:var(--text) !important;
    background-color:transparent!important;
}

.suggestionForm, .betaForm {
    width:42%;
    margin-left:5%;
}

.suggestionForm .button {
    width:160px;
    background-color: transparent;
    color: var(--text);
    border: solid;
    border-width: 1px !important;
    border-color: var(--text) !important;
}