@import './variables.css';


.pageBody {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
}

html, body {
    min-height: 100vh!important;
    color: var(--text) !important;
    display: block;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-style: normal;
}

.page {  /*for everything other than navbar */
    background: var(--gradient);
}

.content-wrap {
    min-height: 100vh !important;
    padding-bottom: 2.5rem;
    position: relative;
    padding-bottom: 100px;
    display: block;
    overflow: hidden;
}

.footer {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.5rem;
    font-size:80%;
}

a {
    color: var(--text);
}

a:hover {
    color: var(--text);
}


.transparentBG {
    background-color: transparent !important;
}

.blackBG {
    background-color: black !important;
}

.popup {
    background-color: var(--secondary_bg) !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 10pt !important;
}

.centered {
    text-align: center;
}

.textCol {
    color: var(--text);
}

.errorMsg {
    position: absolute;
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
    z-index: 5;
    margin-top: 2%;
    font-size: 120%;
}

::-webkit-scrollbar {
    width: 0px; /* remove scrollbar space*/
}

.inlineHeader {
    display: inline-block;
}

/* BUTTONS */

.button.icon {
    background-color: transparent!important;
    color: var(--text) !important;
}

.button.icon:hover {
    color: var(--primary_blue) !important;
    /*background-color: var(--text) !important;*/
}

.button {
    text-align: center;
    background-color: transparent!important;
    color: var(--text) !important;
}

/* TABLE */

.mainTable {
    display: table;
    table-layout: fixed;
    width: 100%;
    background-color: transparent !important;
    color: var(--text) !important;
    text-align: left !important;
    border-collapse: collapse;
}

.mainTable th {
    background-color: transparent !important;
    color: var(--text) !important;
    text-align: left !important;
    padding: 10px;
    font-size: 10pt;
    font-weight: bold;
    padding-bottom: 20px;
}

.mainTable td {
    padding: 10px;
    font-size: 12pt;
    border-bottom: 1pt solid rgb(133, 133, 133) !important;
}

/* NAV BAR */


.navBarBg {
    /*position: absolute;*/
    background-color: var(--secondary_bg) !important;
}

.navBar {
    border: none !important;
}

.backToDashboard {
    font-size: 16px !important;
}

.backToDashboard:hover {
    color: var(--text_hl) !important;
}

.navBar .accDropdown .menu {
    background-color: var(--secondary_bg) !important;
    color: var(--text) !important;
}

.navBar .accDropdown .testing {
    color: var(--text) !important;
}

.navBar .logo {
}


/* ACCOUNT */


.account {
    padding: 10%;
}

.account .button {
    background-color: transparent;
    border: solid;
    border-width: 0px;
    border-color: var(--text);
    color: var(--text);
    margin-bottom: 3%;
}


/* REPORT */

