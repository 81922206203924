
html {
    --primary_bg: #343B52; /* main page background */
    --secondary_bg: #161E30; /* background of lhs and rhs of patient page and nav bar */
    --inv_text:#19191b;
    --text:rgb(235, 235, 235);
    --text_hl: white;
    --dashboard_tile_bg: #2A3047;
    --hoverButton: #05D0FF;
    --primaryBlue: #05D0FF;
    --lowPriority: #34E9B4;
    --mediumPriority: #FDD748;
    --highPriority: #FF6969;
    --line-stenosis: #FF8700;
    --line-plaque: #34E9B4;
    --gradient: linear-gradient(180deg, var(--primary_bg) 0%, var(--secondary_bg) 100%);
}

html[data-theme='1'] {
    --primary_bg: rgb(44, 48, 53); /* main page background */
    --secondary_bg: rgb(29, 30, 32); /* background of lhs and rhs of patient page and nav bar */
    --inv_text:#19191b;
    --text:rgb(235, 235, 235);
    --text_hl: white;
    --dashboard_tile_bg: rgb(152, 170, 241);
    --hoverButton: #05D0FF;
    --primaryBlue: #05D0FF;
    --lowPriority: #34E9B4;
    --mediumPriority: #FDD748;
    --highPriority: #FF6969;
    --line-stenosis: #FF8700;
    --line-plaque: #34E9B4;
}

html[data-theme='2'] {
    --primary_bg: rgb(40, 40, 41); /* main page background */
    --secondary_bg: rgb(23, 23, 24); /* background of lhs and rhs of patient page and nav bar */
    --inv_text:#19191b;
    --text:rgb(235, 235, 235);
    --text_hl: white;
    --dashboard_tile_bg: rgb(152, 170, 241);
    --hoverButton: #05D0FF;
    --primaryBlue: #05D0FF;
    --lowPriority: #34E9B4;
    --mediumPriority: #FDD748;
    --highPriority: #FF6969;
    --line-stenosis: #FF8700;
    --line-plaque: #34E9B4;
}

html[data-theme='3'] {
    --primary_bg: rgb(72, 166, 180); /* main page background */
    --secondary_bg: rgb(33, 72, 92); /* background of lhs and rhs of patient page and nav bar */
    --inv_text:#19191b;
    --text:rgb(236, 236, 236);
    --text_hl: white;
    --dashboard_tile_bg: rgb(152, 170, 241);
    --hoverButton: #05D0FF;
    --primaryBlue: #05D0FF;
    --lowPriority: #34E9B4;
    --mediumPriority: #FDD748;
    --highPriority: #FF6969;
    --line-stenosis: #FF8700;
    --line-plaque: #34E9B4;
}

html[data-theme='4'] {
    --primary_bg: rgb(255, 255, 255); /* main page background */
    --secondary_bg: rgb(160, 183, 212); /* background of lhs and rhs of patient page and nav bar */
    --inv_text:#19191b;
    --text:rgb(41, 41, 41);
    --text_hl: white;
    --dashboard_tile_bg: rgb(152, 170, 241);
    --hoverButton: #05D0FF;
    --primaryBlue: #05D0FF;
    --lowPriority: #34E9B4;
    --mediumPriority: #FDD748;
    --highPriority: #FF6969;
    --line-stenosis: #FF8700;
    --line-plaque: #34E9B4;
}

html[data-theme='5'] {
    --primary_bg: rgb(255, 130, 238); /* main page background */
    --secondary_bg: rgb(108, 228, 255); /* background of lhs and rhs of patient page and nav bar */
    --inv_text:#19191b;
    --text:rgb(41, 41, 41);
    --text_hl: white;
    --dashboard_tile_bg: rgb(152, 170, 241);
    --hoverButton: #05D0FF;
    --primaryBlue: rgb(68, 17, 122);
    --lowPriority: #34E9B4;
    --mediumPriority: #FDD748;
    --highPriority: #FF6969;
    --line-stenosis: #FF8700;
    --line-plaque: #34E9B4;
}

html[data-theme='6'] {
    --primary_bg: rgb(202, 26, 26); /* main page background */
    --secondary_bg: rgb(83, 206, 76); /* background of lhs and rhs of patient page and nav bar */
    --inv_text:#19191b;
    --text:rgb(41, 41, 41);
    --text_hl: white;
    --dashboard_tile_bg: rgb(152, 170, 241);
    --hoverButton: #05D0FF;
    --primaryBlue: rgb(68, 17, 122);
    --lowPriority: #34E9B4;
    --mediumPriority: #FDD748;
    --highPriority: #FF6969;
    --line-stenosis: #FF8700;
    --line-plaque: #34E9B4;
}

html[data-theme='7'] {
    --primary_bg: rgb(83, 192, 66); /* main page background */
    --secondary_bg: rgb(139, 76, 206); /* background of lhs and rhs of patient page and nav bar */
    --inv_text:#19191b;
    --text:rgb(41, 41, 41);
    --text_hl: white;
    --dashboard_tile_bg: rgb(152, 170, 241);
    --hoverButton: #05D0FF;
    --primaryBlue: rgb(68, 17, 122);
    --lowPriority: #34E9B4;
    --mediumPriority: #FDD748;
    --highPriority: #FF6969;
    --line-stenosis: #FF8700;
    --line-plaque: #34E9B4;
}

html[data-theme='8'] {
    --primary_bg: rgb(102, 94, 167); /* main page background */
    --secondary_bg: rgb(154, 224, 228); /* background of lhs and rhs of patient page and nav bar */
    --inv_text:#19191b;
    --text:rgb(41, 41, 41);
    --text_hl: white;
    --dashboard_tile_bg: rgb(152, 170, 241);
    --hoverButton: #05D0FF;
    --primaryBlue: rgb(68, 17, 122);
    --lowPriority: #34E9B4;
    --mediumPriority: #FDD748;
    --highPriority: #FF6969;
    --line-stenosis: #FF8700;
    --line-plaque: #34E9B4;
}

html[data-theme='9'] {
    --primary_bg: rgb(255, 0, 221); /* main page background */
    --secondary_bg: rgb(255, 217, 0); /* background of lhs and rhs of patient page and nav bar */
    --inv_text:#19191b;
    --text:rgb(41, 41, 41);
    --text_hl: white;
    --dashboard_tile_bg: rgb(152, 170, 241);
    --hoverButton: #05D0FF;
    --primaryBlue: rgb(68, 17, 122);
    --lowPriority: #34E9B4;
    --mediumPriority: #FDD748;
    --highPriority: #FF6969;
    --line-stenosis: #FF8700;
    --line-plaque: #34E9B4;
}