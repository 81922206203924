@import './variables.css';

/* SCAN */

.scan .pageHeader {
    background-color: transparent !important;
    width: 100%;
    display: inline-block;
}

.scan .main.tabular.menu {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-left: 5% !important;
}

.scan .main.tabular.menu a {
    color: var(--text) !important;
    border: none !important;
}

.scan .main.tabular.menu .item {
    border-radius: 1px !important;
    border-width: 1px !important; /* match width when active */
}

.scan .main.tabular.menu .item.active {
    background-color: var(--secondary_bg);
    border-top: solid !important;
    border-left: solid !important;
    border-right: solid !important;
    border-width: 1px !important;  /* match width when inactive */
    border-color: rgba(224, 224, 224, 0.394) !important;
}

.scan .main.tabular.menu .dropdown.selection {
    width: 20% !important; /* brings the dropdown arrow in closer */
    min-width: 20% !important;
    border-color: transparent;
}

.scan .main.tabular.menu .dropdown i {
    position: absolute;
    top: 30%; /* bring dropdown arrow in line with text */
}

.scan .main.tabular.menu .dropdown .item {
    background-color: var(--primary_bg) !important;
}

.scan .main.tabular.menu .dropdown:hover {
    border-color: transparent;
}

.scan .main.tabular.menu .dropdown .item.selected {
    background-color: var(--primary_bg) !important;
}

.scan .main.tabular.menu .dropdown .text {
    color: var(--text);
}

.scan .main.tabular.menu .dropdown .item:hover {
    background-color: var(--secondary_bg) !important;
}

.mainDiv {
    border-top: solid;
    border-width: 1px;
    border-color: rgba(224, 224, 224, 0.394);
    box-shadow: 0 5px 20px 0 rgba(0,0,0,0.2);
    border-radius: 2px;
}

.topDiv .patientId:hover {
    cursor: default
}

/* LEFT HAND SIDE */

.scan .lhs {
    width: 48%;
    position: relative;
    float: left; /* for s to be inline */
}

.scan .lhs .viewer {
    background-color: var(--secondary_bg) !important;
    min-height: 100px;
    padding: 3%;
}

.scan .lhs .viewer.ctvolume .buttons .button.active {
    text-decoration: underline;
}

.scan .lhs .viewer.ctvolume .buttons .button {
    padding-left: 0;
    font-size: 12px;
}

.scan .lhs .viewer#scene-container {
    padding: 0; /* dont pad lhs when showing threejs model */
}

/* disable text highlighting (happys when click+drag on 3d model */
.scan .lhs .viewer .overlay, .legend-markers div {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.scan .lhs .viewer .model .dropdown {
    border: none;
    color: var(--text);
    position: absolute;
    top: 10%;
    right: 5%;
    width: 15%; /* fix width so it doesnt change based on length of selected string */
    z-index: 23; /* put in front of canvas so it is clickable */
}

.scan .lhs .viewer .model .dropdown .menu, .item, .text {
    color: var(--text) !important;
}

.scan .lhs .viewer .model .dropdown .menu, .item {
    background-color: var(--primary_bg);
}

.scan .lhs .viewer .model .dropdown i {
    position: absolute;
    top: 25%;
}

.scan .lhs .viewer .model .legend {
    position: absolute;
    margin-top: 1%;
    top: 15%;
    right: 5%;
    /* put in front of canvas so it is clickable */
    z-index: 22;
}

.scan .lhs .viewer .overlay {
    position: absolute;
    z-index: 5;
    top: 10%;
    left: 2%;
    width: 100%;
}

.scan .lhs .viewer .overlay .button {
    border: none!important;
    margin-bottom: 1%;
}

.scan .lhs .viewer .analysis {
    padding: 3%;
    background: var(--gradient);
}

.scan .lhs .viewer .analysis h4 {
    font-size: 18px;
    font-weight: 100;
}

.scan .lhs .viewer .analysis table {
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    text-align: left !important;
    width: 100%;
    border-collapse: collapse;
}

.scan .lhs .viewer .analysis th {
    font-size: 13px;
}

.scan .lhs .viewer .analysis td {
    font-size: 13px;
    border-bottom: 1pt solid rgb(133, 133, 133) !important;
    border-width: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 25px;
}


/* H5 slicer components (on lhs & rhs) */

.scan .slicer-content {
    display: flex;
    justify-content: space-between;
    position: relative;
    min-height: 200px;
}

.scan .slicer-content .loader{
    position: absolute;
    width : 10%;
    left: 40%;
    right: 40%;
    top: 5%;
}

.scan .slicer-content canvas, .slicer-canvas-holder {
    width: 100%;
    background-color: black;
}

.scan .slicer-slider .rc-slider-rail, .rc-slider-track {
    background-color: var(--primary_bg);
}

.scan .slicer-slider .rc-slider-handle {
    background-color: transparent;
    border: solid;
    border-width: 2px;
    border-color: var(--text);
}

.scan .slicer-slider {
  margin-right:-10px;
}

/* RIGHT HAND SIDE */

.scan .rhs {
    position: relative;
    margin-left: 52% !important; /* 100 - lhs width + lhs margin left */
}

.scan .rhs .container {
    background-color: var(--secondary_bg) !important;
    padding: 3%;
    display: block;
}

.scan .rhs .notes {
    width: 100%;
}

.notes textarea {
    height: 150px;
    border: none !important;
    color: var(--text) !important;
    background-color: var(--secondary_bg) !important;
}

.scan .topDiv {
    position: relative;
    height: 70px;
}

.scan .topDiv .patientId {
    position: absolute;
    top: 25%;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.scan .topDiv .scanSelection {
    width: 20%;
    border-bottom: solid;
    border-width: 1px;
}

.scan .topDiv .scanSelection .dropdown {
    width: 75% !important; /* brings the dropdown arrow in closer */
    min-width: 75% !important;
    border-color: transparent;
    z-index: 10;
}

.scan .topDiv .scanSelection .dropdown .item {
    background-color: var(--secondary_bg);
    color: var(--text);
    border-color: transparent;
}

.scan .topDiv .scanSelection .dropdown .item:hover {
    background-color: var(--primary_bg);
    border-color: transparent;
}

.scan .topDiv .scanSelection .dropdown .text {
    color: var(--text) !important;
}

.scan .approveButton {
    right: 0;
    top: 0;
    margin: 0;
    color: var(--primaryBlue) !important;
    min-width: 10%;
    position: absolute;
    border: solid;
    border-width: 1px;
    border-radius: 1px;
    padding-top: 10px !important;
    padding-bottom: 10px!important;
    padding-left: 3%!important;
    padding-right: 3%!important;
}

.approveButton:hover {
    background-color: var(--primaryBlue) !important;
    color: black !important;
}

.scan .rhs .statTiles {
    display: flex;
    justify-content: space-between;
}

.scan .rhs .statTile {
    width: 100%;
    border: solid;
    border-width: 1px;
    border-radius: 1px;
    border-color: var(--primary_bg);
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 3%;
    max-height: 100%;
    position: relative;
}


/*
.scan .rhs .statTile:hover .inner {
    transform: rotateY(180deg);
    height: 100%;
}
*/

.scan .rhs .statTile .inner {
    width: 100%;
    height: 100px;
    /*
    transition: transform 0.8s;
    transform-style: preserve-3d;
    */
    position: relative;
}

.scan .rhs .statTile .front, .back {
    width: 100%;
    height: 100%;
    position: absolute;
    /*
    backface-visibility: hidden;
    */
}

.scan .rhs .statTile a {
    position: absolute;
    bottom: 5%;
    right: 0;
    color: var(--primary_bg);
}

.scan .rhs .statTile a .clickable:hover {
    color: var(--hoverButton);
}


.scan .rhs .statTile:hover {
    border-color: var(--primary_bg);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
}

.scan .rhs .statTile.selected {
    border-color: var(--primary_bg);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
}

.scan .rhs .statTile .topHalf {
    display: flex;
    align-items: flex-end;
    height: 50%;
    font-weight: 100;
}

.scan .rhs .statTile .bottomHalf {
    font-weight: 700;
    font-size: 10pt
}

.scan .rhs .secondary.tabular.menu {
    padding: 0 !important;
    margin: 0 !important;
}

.scan .rhs .secondary.tabular.menu a {
    margin: 0!important;
    border: solid;
    border-color: transparent;
    background-color: transparent;
}

.scan .rhs .secondary.tabular.menu .active {
    border: solid;
    border-color: var(--primary_bg) ! important;
    background-color: var(--primary_bg) !important;
}

.scan .rhs .secondary.tabular.menu a {
    color: var(--text) !important;
}

.scan .rhs .downloadReportContainer {
    position: relative;
}

.scan .rhs .downloadReport {
    position: absolute;
    bottom: 0;
    right: 0;
}

.scan .rhs .patientReport {
    font-size: 13px;
}

.scan .rhs .patientSummary td {
    font-size: 13px;
}

.scan .rhs .patientSummary tbody td:first-child:hover {
    /* show that first column ('Vessel') clickable */
    text-decoration: underline;
    cursor: pointer;
}

.scan .rhs .patientSummary, .patientReport, .vesselViewer {
    padding: 5%;
    background: var(--gradient);
}

.scan .rhs .patientSummary, .patientReport {
    max-height: 500px;
    overflow: auto;
}

.scan .rhs .patientReport p {
    font-weight: 400;
}

.scan .rhs .patientReport .dropdown{ /* editable patient report */
    color: var(--text);
    background-color: transparent;
    padding-right: 3px;
    padding-left: 3px;
}

.scan .rhs .patientReport .dropdown .item {
    background-color: var(--secondary_bg);
    border-bottom: solid;
    border-width: 1px !important;
    border-color: var(--text);
}

.scan .rhs .patientReport .dropdown .item:hover {
    background-color: var(--primary_bg);
}

.scan .rhs .patientReport .dropdown .text{
    text-decoration: underline;
    color: var(--text);
}

.scan .rhs .patientReport .dropdown .item.selected .text{
    text-decoration: underline;
    font-weight: 400;
}

.scan .rhs .patientReport .dropdown .item .text {
    text-decoration: none;
}

.scan .rhs .vesselViewer {
    display: flex;
    justify-content: space-between;
}

.scan .rhs .vesselViewer .plotContainer {
    width: 25%;
    position: relative;
}

.scan .rhs .vesselViewer .legend, .mprToggle, .mprSlicer .annoToggle {
    /* match styles of .legend */
    font-size: 10pt;
    margin-bottom: 1% !important;
    height: 5% !important;
}

.scan .rhs .vesselViewer .mprToggle, .annoToggle {
    padding: 0 !important;
}

.scan .rhs .vesselViewer .annoToggle:hover {
    color: var(--hoverButton) !important;
}

.scan .rhs .vesselViewer .mprToggle .underline {
    border-color: var(--text) !important;
}

.scan .rhs .vesselViewer .toggleButtons span {
    background-color: var(--primary_bg);
    border-radius: 5px;
    padding: 1%;
    margin-left: 5%;
    border: solid;
    border-width: 1px;
    border-color: transparent !important;
    cursor: pointer;
}

.scan .rhs .vesselViewer .plotContainer .legend .hide {
    text-decoration: line-through;
}

.scan .rhs .vesselViewer .plotContainer .legend .plaque {
    background-color: var(--line-plaque) !important;
    color: black;
    border-radius: 5px;
    padding: 0.5%;
}

.scan .rhs .vesselViewer .plotContainer .legend .stenosis {
    background-color: var(--line-stenosis) !important;
    color: black;
    border-radius: 5px;
    padding: 0.5%;
}

.scan .rhs .vesselViewer .plotContainer #container {
    height: 643px;
}

g.amcharts-Sprite-group.amcharts-Container-group.amcharts-AmChartsLogo-group {
    display: none;
    height: 0px;
}

.scan .rhs .vesselViewer .smprSlicer, .cprSlicer {
    width: 50%;
}

.scan .rhs .vesselViewer .cprWrapper{
    width:100%;
    position:relative;
}
.scan .rhs .vesselViewer .cprWrapper .button {
    position: absolute;
    height: 20px;
    padding: 5px;
    margin: 0px;
    top: 0;
    right: 0;
    z-index: 100;
}

.scan .rhs .vesselViewer .cprWrapper .cprStage{
    background-color:#000;
    width:100%;
}

/*
.scan .rhs .vesselViewer .smprSlicer canvas {
    max-height: 600px;
}
*/

.scan .rhs .huContainer{
    position: absolute;
    pointer-events: none;
    margin-top:24px;
    width: 115px;
    height: 30px;
    top: 0;
    right: 0;
    z-index: 100;
}

.scan .rhs .huContainer .huBackground {
    position: absolute;
    width:100%;
    height:100%;
    opacity: 0.4;
    background-color:#000;
    z-index: -1;
}

.scan .rhs .huContainer .huLabel {
    position: absolute;
    width: 100%;
    height:100%;
    text-align: left;
    padding: 5px;
    padding-left:10px;
}

.scan .rhs .vesselViewer .mprSlicer {
    width: 40%;
}

.scan .rhs .vesselViewer .windower {
    font-size: 10pt;
    padding: 5%;
    position: relative;
}

.scan .rhs .vesselViewer .windower input {
    width: 25%;
    margin-left: 5%;
    background-color: transparent;
    color: var(--text);
    border: none;
}

.scan .rhs .vesselViewer .windower .button {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    z-index: 100;
}

.scan .rhs .vesselViewer .windower .rc-slider-rail, .rc-slider-track {
    background-color: var(--primary_bg);
}

.scan .rhs .vesselViewer .windower .rc-slider-handle {
    background-color: transparent;
    border: solid;
    border-width: 2px;
    border-color: var(--text);
}

.scan .rhs .vesselViewer .mprInfo input {
    background-color: transparent;
    color: var(--text);
    border: none;
}

.scan .rhs .vesselViewer .mprInfo tr td:first-child {
    font-size: 13px;
    padding-right: 20%;
    width: 80%;
}

.scan .rhs .vesselViewer .mprInfo tr td:last-child {
    font-size: 13px;
    font-weight: 700;
}
