/* for account.js */

.accountContainerWrapper{
	margin:auto;
	width:1000px
}

.accountContainerWrapper .accountContentWrapper{
	text-align: center;
	width:100%;
	display:flex;
}
@media screen and (max-width:1000px){
	.accountContainerWrapper{
		width:100%;
	}
}

.accountContentWrapper .lhsWrapper{
	width: 30%;
	display:block;
	background-color:#202840;
}

.accountContentWrapper .lhsWrapper .menuItem{
	width:100%;
	height:70px;
	line-height: 70px;
	font-size: 24px;
}

.accountContentWrapper .menuItem .menuLabel:hover{
	cursor:pointer;
}

.accountContentWrapper .menuLabel .selectableItem{
	width:100%;
	background-color:#202840;
}

.accountContentWrapper .menuLabel .selectableItem.active{
	width:100%;
	background-color:#404860;
}

.accountContentWrapper .rhsWrapper{
	width:70%;
	padding-top:5px;
	padding-left:20px;
	padding-right:10px;
	display:block;
	text-align:left;
	background-color:#586078;
}

.accountContentWrapper .rhsWrapper .col1{
	width: 60%;
}

.accountContentWrapper .rhsWrapper .col2{
	width: 10%;
}

.accountContentWrapper .rhsWrapper .col3{
	width: 30%;
}

.accountContentWrapper .rhsWrapper label{
	background-color: transparent !important;
}

.accountContentWrapper .lhsWrapper .menuItem .menuLabel .menuInput{
	appearance:none;
	-webkit-appearance: none;
	-moz-appearance: none;
	position:fixed;
}
